import React from 'react';
import Swal from 'sweetalert2'
import 'bootstrap/dist/css/bootstrap.min.css';
import "./login.css"
import $ from "jquery";
import Table from ".././ordersTable/Table";

export default class Login extends React.Component{
    state={
        formData:{
            u:"",
            p:""
        },
        isLoggedIn:false
    }
    componentDidMount(){
        let x = this.getCookie('siteadmin');
        if(x){
            this.setState({
                isLoggedIn:true
            })
        }
    }
    handleClick = (e) =>{
        e.preventDefault();
        let u = document.getElementById("u").value;
        let p = document.getElementById("p").value;
        const settings = {
            method:"POST",
            header:{
                'content-type':'application/json'
            },
            data:{
                'username':u,
                'password':p,
            },
            url:'http://dash.310nutrition.com/login.php'
        } 
        $.ajax(settings).then(this.onSuccess).catch(this.onFail)
    }
    onSuccess = (res)=>{
        console.log(res)
        if(res === "Logged in"){
            this.setState({
                isLoggedIn:true
            }, ()=>{this.setCookie("siteadmin", this.makeid(7), 1);
            })
        }else{
            Swal.fire({
                icon:'error',
                title:'Login Failed',
                text:'Something went wrong. Please check your credentials.'
            });
        }
    }
    onFail = (err) =>{
        console.log(err)
    }
    makeid = (length) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }
    setCookie = (name, value, days) =>{
        var expires = "";
        console.log(this.hashCode(value));
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days*24*60*60*1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (this.hashCode(value) || "")  + expires + "; path=/";
        
    }
    getCookie=(name) =>{
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        console.log("getting cookie")
        for(let i = 0; i<ca.length;i++){
            let c = ca[i];
            while(c.charAt(0)===' ') c=c.substring(1,c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
        }
        return null;
    }
    eraseCookie=(name)=>{
        document.cookie= name+"=; Max-Age=-99999999999";
    }
    hashCode = (s) =>{
        var h = 0, l = s.length, i = 0;
        if ( l > 0 )
            while (i < l)
            h = (h << 5) - h + s.charCodeAt(i++) | 0;
            console.log("hashed", h);
        return h;
    }
    render(){
        return<React.Fragment>
            {this.state.isLoggedIn
            ?
                <Table/>
            :
            <div className="background">            
                <div className="form-holder">
                    <form>
                        <h5>310 Orders Dashboard</h5>
                        <div className="form-group">
                            <label>Username: </label>
                            <input className="form-control" id="u" type="text"/>
                        </div>
                        <div className="form-group">
                            <label>Password: </label>
                            <input className="form-control" id="p" type="password"/>
                        </div>
                        <button className="btn btn-primary orange" onClick={this.handleClick}>Login</button>
                    </form>
                </div>
            </div>
            }
            
        </React.Fragment>
    }
}
