import React from "react"

import "./table.css"
import TableRow from "./TableRow"
import {Line, Bar, Pie} from 'react-chartjs-2'
import * as moment from 'moment';
import 'bootstrap/dist/css/bootstrap.min.css'
import Swal from "sweetalert2"
import $ from "jquery";

export default class Table extends React.Component{
    state={
        dates:[],
        totalRevs:[],
        numberOfOrder:[],
        AOV:[],
        orderData:[],
        totalItems:0,
        showPieChart:false,
        formData:{
            dateStart:"",
            dateEnd:"",
            campaignType:"All"
        },
        campaignChartData:{},
        revChartData:{},
        totalRevChartData:{},
        totalOrdersChart:{},
        report:[]
    }
    componentDidMount(){
        //DEBUG ONLY 
        //this.eraseCookie('siteadmin')
    }
    //DEBUG ONLY
    eraseCookie=(name)=>{
        console.log("erasing cookie");
        document.cookie= name+"=; Max-Age=-99999999999";
    }
    getAllOrders=(dateStart,dateEnd,campaignType)=>{
        let search = campaignType;
        let dE = moment(dateEnd)
        
        if(search === "All"){
            search = ""
            this.setState({
                showPieChart:true
            },()=>{
                const settings = {
                    method:"POST",
                    header:{
                        'content-type':'application/json'
                    },
                    data:{
                        'processed_min':dateStart,
                        'processed_max':dateEnd,
                        'campaign_type':search
                    },
                    url:'http://dash.310nutrition.com/test.php'
                } 
                $.ajax(settings).then(this.getAllOrdersSuccess).catch(this.genericFailure);
            })
        }
        else{
            this.setState({
                showPieChart:false
            },()=>{
                const settings = {
                    method:"POST",
                    header:{
                        'content-type':'application/json'
                    },
                    data:{
                        'processed_min':dateStart,
                        'processed_max':dateEnd,
                        'campaign_type':search
                    },
                    url:'http://dash.310nutrition.com/test.php'
                } 
                $.ajax(settings).then(this.getAllOrdersSuccess).catch(this.genericFailure);
            })   
        }
    }
    getAllOrdersSuccess=(data)=>{
        data = JSON.parse(data);
        let dates = this.getDates(data);
        let totalOrdersPerDate = this.getTotalOrdersPerDate(data);
        let totalRevPerDate = this.getTotalRevenuePerDate(data);
        let averagesPerDate = this.getAveragePerDate(data);
        let sorted = this.sortByType(data);
        let rows = this.makeObjectArray(dates,  totalRevPerDate, totalOrdersPerDate, averagesPerDate);
        console.log(rows);
        this.setState({
            orderData:data,
            orderRows:rows.map(this.renderRows),
            totalItems:data.length,
            campaignChartData:{
                labels:['Paid Media','Email'],
                datasets:[{
                    data:sorted,
                    label:'Campaign',
                    backgroundColor:['rgba(138, 223, 221, 1)','rgba(125, 226, 130, 1)']
                }]
            },
            revChartData:{
                labels:dates,
                datasets:[
                {
                    label:'AOV (in $)',
                    data:averagesPerDate,
                    backgroundColor:this.setAOVColors(dates)
                }]
            },
            totalRevChartData:{
                labels:dates,
                datasets:[{
                    label:'Total Prices (in $)',
                    data:totalRevPerDate,
                    backgroundColor:this.setTotalRevColors(dates)
                   
                }]
            },
            totalOrdersChart:{
                labels:dates,
                datasets:[{
                    label:'Total Orders',
                    data:totalOrdersPerDate,
                    backgroundColor:this.setTotalOrdersColors(dates)
                }]
            },
            report:rows
        },()=>{
            
            if(this.state.totalItems === 0){
                Swal.fire({
                    icon:'error',
                    title:'Your search returned no results.',
                    text:'Either your start date is greater than your end date or there are no orders in the specified range.'
                })
            }
        })
    }
    download=(data)=>{
        const blob = new Blob([data],{type:'text/csv'});
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.setAttribute('hidden', '');
        a.setAttribute('href',url);
        a.setAttribute('download','download.csv')
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }
    arrayToCsv = (analytics) =>{
        let csvRows = [];
        //get headers
        const headers = ["date","totalRev","totalOrders","AOV"];
        csvRows.push(headers.join(','));
        //loop over rows
        for(const row of analytics){
            let values = headers.map(header=>{
                const escaped = (''+row[header]).replace(/"/g, '\\"')
                return `"${escaped}"`
            })
            csvRows.push(values.join(','));
        }
        //form escaped comma separated values
        return csvRows.join('\n');
    }
    getReport = () =>{
        if(this.state.totalItems > 0){
            let csvData = this.arrayToCsv(this.state.report)
            console.log("CSV DATA", this.state.report)
            this.download(csvData);
        }else{
            Swal.fire({
                text:"no data to export"
            })
        }
    }
    sortByType = (shopifyData) =>{
        let email = 0;
        let paidMedia = 0;
        let sorted = [];
        shopifyData.forEach(order =>{
            if(order.campaign_type === "Email"){
                email++;
            }
            else{
                paidMedia++;
            }
        })
        sorted.push(paidMedia)
        sorted.push(email)
        return sorted;
    }
    setAOVColors = (dates) =>{
        let colours = [];
        dates.forEach(date=>{
            colours.push('rgba(144, 0, 127, 1)')
        })
        return colours
    }
    setTotalRevColors = (dates) =>{
        let colours = []
        dates.forEach(date=>{
            colours.push('rgba(255, 138, 66, 1)')
        })
        return colours
    }
    setTotalOrdersColors = (dates) =>{
        let colours = []
        dates.forEach(date=>{
            colours.push('rgba(48, 126, 255, 1)')
        })
        return colours
    }
    getDates = (shopifyData) =>{
        let dates = [];//dates to show
        let count = 0;
        var arr=[];
        //push all dates from shopify order to date array
        shopifyData.forEach(order=>{
            dates.push(order.processed_at.substring(0,10));
        })
        //filter the dates for no duplicates
        dates=dates.filter((v,i)=> dates.indexOf(v)===i)
        //go through each date and add the dates that didn't show up
        dates.forEach(date=>{
            if(count > 0){    
                let start = moment(this.state.formData.dateStart)
                let stop = moment(this.state.formData.dateEnd)
                while(start <= stop){
                    arr.push(moment(start).format('YYYY-MM-DD'))
                    start = moment(start.add(1, 'days'));
                    for(let i = 0; i < arr.length; i++){
                        if(i>0){
                            dates.push(arr[i]);
                        }
                    }
                }
            }
            count++;
        })
        dates=dates.filter((v,i)=> dates.indexOf(v)===i)
        dates.sort(this.sortDatesInAsc)
        let oneMoreDate = new Date(dates[dates.length-1])
        oneMoreDate.setDate(oneMoreDate.getDate());
        console.log(oneMoreDate);
        let lastDay = moment(oneMoreDate).add(1,'days');
        console.log(moment(lastDay).format('YYYY-MM-DD'))
        dates.push(moment(lastDay).format('YYYY-MM-DD'))
        dates=dates.filter((v,i)=> dates.indexOf(v)===i)
        return dates
    }
    sortDatesInAsc = (date1,date2) =>{
        if (date1 > date2) return 1;
        if (date1 < date2) return -1;
        return 0;
    }
    getTotalOrdersPerDate = (shopifyData) =>{
        let totalOrdersPerDate = [];
        let dates = this.getDates(shopifyData);
        let shopifyDate = "";
        let totalForOneDate = 0;
        dates.forEach(date =>{
            totalForOneDate = 0;//reset tfod to 0
            for(let i = 0; i<shopifyData.length; i++){
                shopifyDate = shopifyData[i].processed_at.substring(0,10)
                if(date === shopifyDate){
                    totalForOneDate++;
                }
            }
            totalOrdersPerDate.push(totalForOneDate);
        })
        return totalOrdersPerDate;
    }
    getTotalRevenuePerDate = (shopifyData) =>{
        let totalMoneyPerDate = [];
        let dates = this.getDates(shopifyData);
        let shopifyDate = "";
        let totalForOneDate = 0;
        let price = 0;
        dates.forEach(date=>{
            totalForOneDate = 0;
            for(let i = 0; i<shopifyData.length; i++){
                shopifyDate = shopifyData[i].processed_at.substring(0,10);
                price = parseFloat(shopifyData[i].total_price)
                if(date === shopifyDate){
                    totalForOneDate = parseFloat(totalForOneDate) + price;
                }
            }
            totalMoneyPerDate.push(parseFloat(totalForOneDate).toFixed(2));
        })
        return totalMoneyPerDate
    }
    getAveragePerDate = (shopifyData) =>{
        let averages = [];
        let totals = this.getTotalOrdersPerDate(shopifyData);
        let revenues = this.getTotalRevenuePerDate(shopifyData);
        let average = 0;
        let count = 0;
        totals.forEach(total =>{
            average = 0;
            average = revenues[count]/total;
            averages.push(average.toFixed(2))
            count++;
        })
        return averages;
    }
    genericFailure=(err)=>{
        console.log(err)
    }
    handleDateChange = (e) =>{
        let name = e.target.name;
        let value = e.target.value;
        this.setState(prevState => {
            return{
                ...prevState,
                formData:{
                    ...prevState.formData,
                    [name]:value
                }
            }
        }, console.log(this.state.formData))
    }
    handleCampaignChange = (e)=>{
        let value = e.target.value;
        this.setState(prevState=>{
            return{
                ...prevState,
                formData:{
                    ...prevState.formData,
                    campaignType:value
                }
            }
        })
    }
    makeObjectArray = (dates, totalRev, totalOrders, AOV) =>{
        let tableRowObject = {
            date:"",
            totalRev:0,
            totalOrders:0,
            AOV:0
        }
        let rowArray = [];
        let count = 0;
        dates.forEach(date=>{
            tableRowObject = {
                date:"",
                totalRev:0,
                totalOrders:0,
                AOV:0
            }
            tableRowObject={
                date:date,
                totalRev:totalRev[count],
                totalOrders:totalOrders[count],
                AOV:AOV[count]
            }
            rowArray.push(tableRowObject);
            count++;
        })
        return rowArray;
    }
    renderRows=(dailyStats)=>{
        return(
            <TableRow
                key={dailyStats.date}
                stats={dailyStats}
            />
        )
    }
    handleClick = (e) =>{
        e.preventDefault();
        this.getAllOrders(this.state.formData.dateStart, 
            this.state.formData.dateEnd, 
            this.state.formData.campaignType)
    }
    render(){
        return <React.Fragment>
            <div className="container">
                <h3 className="mt-3">310 Nutrition Orders - Data Visualisation</h3>
                <hr/>
                <div className="row">
                    <div className="col-lg-3 col-sm-12"/>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                    <h5 className="mt-3">Query</h5>
                    <form>
                        <div className="form-row mt-3">
                            <div className="col">
                            <label htmlFor="dateStart">Date Start</label>
                            <input
                                type="date"
                                name="dateStart"
                                value={this.state.formData.dateStart}
                                className="form-control"
                                onChange={this.handleDateChange}
                            ></input>
                            </div>
                            <div className="col">
                            <label htmlFor="dateEnd">Date End</label>
                                <input
                                    type="date"
                                    name="dateEnd"
                                    className="form-control"
                                    value={this.state.formData.dateEnd}
                                    onChange={this.handleDateChange}
                                ></input>
                            </div>
                        </div>
                        <div className="form-row mt-3">
                            <div className="col">
                            <label htmlFor="campaignType">Campaign Type</label>
                            <select 
                                className="form-control"
                                name="campaignType"
                                value={this.state.formData.campaignType}
                                onChange={this.handleCampaignChange}
                            >
                                <option value="All">All</option>
                                <option value="Email">Email/PostCard</option>
                                <option value="Paid Media">Paid Media</option>
                            </select>
                            </div>
                        </div>
                        <div className="form-row mt-3">
                            <div className="col">
                                <button 
                                    className="btn btn-primary"
                                    onClick={this.handleClick}
                                >Submit Query</button>
                            </div>
                        </div>
                    </form>
                    </div>
                    <div className="col-lg-3 col-sm-12"/>
                </div>
                <div className="row mt-5 pb-2">
                    <div className="col-12">
                <p>Total Orders in Query: {this.state.totalItems}</p>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h5>AOV</h5>
                        <Bar
                            data={this.state.revChartData}
                            options={
                                {
                                    scales: {
                                      yAxes: [{
                                        ticks: {
                                          beginAtZero: true
                                        }
                                      }]
                                    }
                                  }
                            }
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h5>Total Revenue</h5>
                        <Bar
                            data={this.state.totalRevChartData}
                            options={
                                {
                                    scales: {
                                      yAxes: [{
                                        ticks: {
                                          beginAtZero: true
                                        }
                                      }]
                                    }
                                  }
                            }
                        />
                    </div>
                </div>
                <div className="row mt-1 pb-5">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h5>Total Orders</h5>
                        <Bar
                            data={this.state.totalOrdersChart}
                            options={
                                {
                                    scales: {
                                      yAxes: [{
                                        ticks: {
                                          beginAtZero: true
                                        }
                                      }]
                                    }
                                  }
                            }
                        />
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <button class="btn btn-primary mb-3" onClick={this.getReport}>Export Table to CSV</button>
                        <h5>Table View</h5>
                        <table className="table">
                            <thead>
                                <th scope="col">Date</th>
                                <th scope="col">Total Revenue</th>
                                <th scope="col">Number of Orders</th>
                                <th scope="col">AOV</th>
                            </thead>
                            <tbody>
                                {this.state.orderRows}
                            </tbody>
                        </table>
                    </div>
                    
                    {this.state.showPieChart?
                        <React.Fragment>
                            <div className="col-lg-6 col-sm-12">
                            <h5>Campaign Types</h5>
                            
                            <Pie
                                    data={this.state.campaignChartData}
                            />
                            </div>
                            <div className="col-6"/>
                        </React.Fragment>
                        : null
                    }
                    
                </div>
            </div>
        </React.Fragment>
    }
}