import React from 'react';
import logo from './logo.svg';
import './App.css';
import Table from './ordersTable/Table'
import Login from './login/Login'
import {Router, Route, Switch} from "react-router"

function App() {
  return (
    <div className="App">
      {/* Debug only */}
      {/* <Table></Table> */}
      <Login/>
    </div>
  );
}

export default App;
