import React from "react"
import * as moment from "moment"
const TableRow = (props) =>{
    const formatPrice=(price)=>{
        return '$' + price.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }
    return(
        <tr>
            <th scope="row">{props.stats.date}</th>
            <td>{formatPrice(props.stats.totalRev)}</td>
            <td>{props.stats.totalOrders}</td>
            <td>${isNaN((props.stats.totalRev/props.stats.totalOrders).toFixed(2))?0:(props.stats.totalRev/props.stats.totalOrders).toFixed(2)}</td>
        </tr>
        )
}

export default TableRow